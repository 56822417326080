<template>
    <div>
        <ListActionsButtons
            :query="$refs?.filters?.query" 
            :disabled-tooltip="disabledNew"
            :request-name="requestName"
            :translation-name="translationName"
            :exportable="exportable"
            :default-url="defaultUrl"
            :permission-name="permissionName"
        />

        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name="permissionName"
            :filters="filters"
            :filters-overload="filtersOverload"
            :tabs-enabled="['basic']"
            :translation-name="translationName"
            @load-data="loadData()"
            @reset-pagination="$refs.pagination?.resetPagination()"
        />
        <v-card class="pa-5">
            <ListTable
                ref="table"
                :permission-name="permissionName"
                :translation-name="translationName"
                :sortable="sortable"
                :default-url="defaultUrl"
                :loading="loading"
                :data="data"
                :columns="columns"
                :value-as-view-link="valueAsViewLink"
                :init-sort="initSort"
                @reset-pagination="$refs.pagination?.resetPagination()"
                @load-data="loadData()"
                @delete-data="deleteData($event)"
            />
            <div>
                <ListPagination
                    ref="pagination"
                    :total="total"
                    :loading="loading"
                    :last-page="lastPage"
                    @load-data="loadData"
                />
            </div>
        </v-card>
        <NuxtPage
            :request-name="requestName"
            :default-url="defaultUrl"
            :translation-name="translationName"
            :permission-name="permissionName"
            @load-data="loadData()"
        />
    </div>
</template>

<script lang="ts">
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

export default {
    name : "Mails",
    extends : tablePageInit,
    setup() {
        useHead({
            title : "Hydraclic | Mails",
            meta : [
                { name : 'description', content : "Envoi de mail massif" }
            ]
        })
        definePageMeta({
            middleware: ['auth']
        });
    },
    data () {
        return {
            disabledNew : "",
            permissionName : 'sentEmail',
            requestName : 'sent_emails',
            defaultUrl : '/gestion/envoi-de-mail',
            translationName : 'gestion.envoidemail',
            filtersOverload : ['template', 'subject', 'communes.code'],
            valueAsViewLink : ["subject"],
            columns : [
                { dataKey : 'credentials.created_at', transform : [this.isDate]},
                { dataKey : 'email_template.name'},
                { dataKey : 'subject' },
                { dataKey : 'communes', template : [this.isMultipleCommuneChips] },
                { dataKey : 'credentials.created_by.name'},
                
            ],
            initSort : {
                // key : "commune",
                // order : "asc"
            },
        }
    },
    mounted () {
        this.$api.setRequest({
            url : `email_templates`,
            method : "GET",
        }).then( res => {
            if (!res.data.length) {
                this.disabledNew = "Au moins un modèle de mail doit être configuré dans les paramètres pour pouvoir envoyer un nouvel e-mail"
            }
        })
        
    },
    methods : {
        isMultipleCommuneChips (key, value) {
            let html = ''
            if (value) {
                const datas = value.map(val => val.nom)
                datas.forEach(data => {
                    html += `<v-chip size="small" class="mr-1">${data}</v-chip>`
                })
            }
            return html
        },
    }
};
</script>
