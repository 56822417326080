<template>
    <div>
        <ModalsLoader :loading="$attrs.loading" />
        <div v-if="!$attrs.loading && data">
            <v-row>
                <v-col cols="12" md="6">
                    <FormSection title="Configuration">
                        <FormInput
                            data-key="title"
                            :validation="v$.data.title"
                            :validation-rules="validationRules"
                            :data="data"
                            :translation-name="translationName"
                            @update-data="data.title = $event"
                        />
                        <div class="d-flex flex-row">
                            <FormInput
                                data-key="file_name"
                                :validation="v$.data.file_name"
                                :validation-rules="validationRules"
                                :data="data"
                                :translation-name="translationName"
                                @update-data="data.file_name = $event"
                            >
                                <template #appendContent>
                                    <DocumentsDownloadLink
                                        :filename="data.file_name"
                                        :url="data.download_url"
                                    >
                                        <v-btn
                                            class="ml-5"
                                            color="info"
                                            icon="mdi-download"
                                            variant="text"
                                        />
                                    </DocumentsDownloadLink>
                                </template>
                            </FormInput>
                        </div>
                    </FormSection>
                </v-col>
                <v-col cols="12" md="6">
                    <FormSection title="Informations">
                        <FormDatetime
                            data-key="credentials.created_at"
                            :enable-time-picker="true"
                            :data="data"
                            :translation-name="translationName"
                        />
                        <FormInput
                            data-key="credentials.created_by.name"
                            :data="data"
                            :translation-name="translationName"
                        />
                    </FormSection>
                </v-col>
            </v-row>
                
            <FormSection title="PEI à créer">
                <span
                    v-if="!data.log?.create_predict?.length"
                    class="text-caption"
                >Aucun PEI à créer lors de l'import</span>
                <v-list>
                    <div class="column_wrapper-3">
                        <v-list-item
                            v-for="item in data.log?.create_predict"
                            :key="item"
                            class="d-block"
                            :title="item.numero_long"
                        />
                    </div>
                </v-list>
            </FormSection>
            <FormSection title="PEI à mettre à jour">
                <span
                    v-if="!data.log?.update_predict?.length"
                    class="text-caption"
                >Aucun PEI à mettre à jour lors de l'import</span>
                <v-list>
                    <div class="column_wrapper-3">
                        <v-list-item
                            v-for="item in data.log?.update_predict"
                            :key="item"
                            class="d-block"
                            :title="item.numero_long"
                        />
                    </div>
                </v-list>
            </FormSection>
        </div>
    </div>
</template>

<script lang="ts">
import formValidationInit from '~/packages/datakode/nuxt-datakode-utils/utils/validations/formValidationInit.vue'
export default {
    name : "Import",
    extends : formValidationInit,
    setup() {
        useHead({
            title : "Hydraclic | Import",
            meta : [
                { name : 'description', content : "Import de documents" }
            ]
        })
        definePageMeta({
            middleware: ['auth']
        });
    },
}
</script>
