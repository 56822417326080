<template>
    <div>
        <ListActionsButtons
            :query="$refs?.filters?.query" 
            :request-name="requestName"
            :translation-name="translationName"
            :exportable="exportable"
            :default-url="defaultUrl"
            :permission-name="permissionName"
        />
        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name="permissionName"
            :filters="filters"
            :filters-overload="filtersOverload"
            :tabs-enabled="['basic']"
            :translation-name="translationName"
            @load-data="loadData()"
            @reset-pagination="$refs.pagination.resetPagination()"
        />
        <v-card class="pa-5">
            <ListTable
                ref="table"
                :permission-name="permissionName"
                :translation-name="translationName"
                :sortable="sortable"
                :default-url="defaultUrl"
                :loading="loading"
                :data="data"
                :columns="columns"
                :value-as-view-link="valueAsViewLink"
                :init-sort="initSort"
                @reset-pagination="$refs.pagination.resetPagination()"
                @load-data="loadData()"
                @delete-data="deleteData($event)"
            />
            <div>
                <ListPagination
                    ref="pagination"
                    :total="total"
                    :loading="loading"
                    :last-page="lastPage"
                    @load-data="loadData"
                />
            </div>
        </v-card>
        <NuxtPage
            :request-name="requestName"
            :default-url="defaultUrl"
            :translation-name="translationName"
            :permission-name="permissionName"
            @load-data="loadData()"
            @delete-data="deleteData($event)"
        />
    </div>
</template>

<script lang="ts">
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

export default {
    name : "SimultaneousList",
    extends : tablePageInit,
    setup() {
        useHead({
            title : "Hydraclic | Contrôles simultanés",
            meta : [
                { name : 'description', content : "Gestion des contrôles simultanés" }
            ]
        })
        definePageMeta({
            middleware: ['auth']
        });
    },
    data () {
        return {
            permissionName : 'simultaneousControl',
            requestName : 'simultaneousControls',
            defaultUrl : '/gestion/controles-simultanes',
            translationName : 'gestion.controlessimultanes',
            filtersOverload : ['k', 'commune_code', 'place', 'numero_long'],
            valueAsViewLink : ['id'],
            columns : [
                { dataKey : 'id' },
                { dataKey : 'date_control', transform : [this.isDate] },
                { dataKey : 'commune.nom' },
                { dataKey : 'place' },
                { dataKey : 'peis', template : [this.isMultipleChips] },
            ],
            initSort : {
                // key : "commune",
                // order : "asc"
            },
        }
    },
};
</script>
