import * as PIXI from 'pixi.js'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component("PIXI", PIXI);
    return {
        provide: {
            PIXI
        },
    };
});
