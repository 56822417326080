<template>
    <div>
        <v-container class="text-center h-screen">
            <v-row
                align="center"
                justify="center"
                class="h-100"
            >
                <v-col>
                    <v-card
                        class="text-center"
                        color="transparent"
                        flat
                    >
                        <div class="grey--text lighten-5 text-h2 mb-5">
                            {{ error.statusCode }}
                        </div>

                        <!-- <img
                        src="@/assets/images/error.png"
                        alt="Discover Nuxt 3"
                        height="300"
                        style="object-fir:contain"
                    > -->

                        <v-icon size="x-large" color="error">
                            mdi-fire-hydrant-alert
                        </v-icon>


                        <div class="paragraph-text mt-2">
                            {{ error.message || "Page non trouvée" }}
                        </div>
                        <v-btn
                            class="mt-7"
                            color="secondary"
                            @click="handleError"
                        >
                            Retourner à l'accueil
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script setup>
const handleError = () => clearError({ redirect: '/' })
// defineProps(['error'])
</script>

<script>
export default {
    layout: 'default',
    props: {
        error: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>