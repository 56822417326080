<template>
    <div>
        <NuxtPage
            ref="display"
            :loading="loading"
            :data="fetchedData"
            :rules="rules"
            :translation-name="'administration.utilisateurs'"
        />
    </div>
</template>

<script lang="ts">
import idPageInit from '~/packages/datakode/nuxt-datakode-utils/utils/idPageInit.vue'
export default {
    extends : idPageInit,
    setup() {
        useHead({
            title : "Hydraclic | Profil",
            meta : [
                { name : 'description', content : "Gestion du profil" }
            ]
        })
        definePageMeta({
            middleware: ['auth']
        });
    },
    data: () => {
        return {
            requestName: 'my_profile',
        }
    },
    computed: {
        currentKey() {
            return ''
        }
    },

}
</script>
