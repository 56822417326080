export class HelperService {


    public getAncestorByComponentName(element, componentName: string) {
        let parent = element.$parent;

        while (parent && parent.$options.name !== componentName) {
            parent = parent.$parent;
        }

        return parent;
    }

}

