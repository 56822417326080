<template>
    <div id="utilisateurs">
        <ListActionsButtons
            :add-options="{ type : ['zone', 'echantillonnage']}"
            :query="$refs?.filters?.query"
            :request-name="requestName"
            :translation-name="translationName"
            :exportable="exportable"
            :default-url="defaultUrl"
            :permission-name="permissionName"
        />

        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name="permissionName"
            :filters="filters"
            :filters-overload="filtersOverload"
            :loading="loading"
            :tabs-enabled="['basic']"
            :translation-name="translationName"
            @load-data="loadData()"
            @reset-pagination="$refs.pagination?.resetPagination()"
        />
        <v-tabs
            v-model="tabs"
            grow
            color="secondary"
            selected-class="tab-active"
        >
            <!-- grow -->
            <v-tab value="list">
                <v-icon>mdi-format-list-checkbox</v-icon>
                <span
                    v-if="!$vuetify.display.mobile"
                    class="ml-3"
                >Liste</span>
            </v-tab>
            <v-tab value="map">
                <!--  @click="loadMapData()" -->
                <v-icon>mdi-map-legend</v-icon>
                <span
                    v-if="!$vuetify.display.mobile"
                    class="ml-3"
                >Carte</span>
            </v-tab>
        </v-tabs>
        <v-card>
            <v-window
                v-model="tabs"
                disabled
            >
                <v-window-item
                    value="list"
                    class="pa-5"
                >
                    <ListTable
                        ref="table"
                        :permission-skip="true"
                        :permission-name="permissionName"
                        :translation-name="translationName"
                        :sortable="sortable"
                        :default-url="defaultUrl"
                        :loading="loading"
                        :columns="columns"
                        :data="data"
                        :init-sort="initSort"
                        :value-as-view-link="valueAsViewLink"
                        @reset-pagination="$refs?.pagination?.resetPagination()"
                        @load-data="loadData()"
                        @delete-data="deleteData($event)"
                    />
                    <div>
                        <ListPagination
                            ref="pagination"
                            :loading="loading"
                            :last-page="lastPage"
                            :total="total"
                            @load-data="loadData"
                        />
                    </div>
                </v-window-item>
                <v-window-item value="map">
                    <Map
                        ref="map"
                        :height="650"
                        :loading="loadingMap"
                        :min-zoom="6"
                        :max-zoom="18"
                        :custom-legend="[{ code : 'peis', translationCode : `gestion.pei.type_hydrant` }]"
                        :custom-legend-icons-path="`${$config.public.apiBase}/map-symbols`"
                        @delete-layer="$refs.map.deleteLayer($event);"
                    >
                        <MapLayerGeojsonGroup
                            v-if="geojson"
                            :geojson="geojson"
                            :selected-feature="[]"
                            :edit="false"
                            layer-name="tournees"
                            :zoom-on-layer="true"
                            :show-layer-at-init="true"
                            show-property="v"
                            :on-update-color="updateColor"
                        />

                        <MapLayerPixiOverlayMarkers
                            v-if="peis"
                            :geojson="peis"
                            layer-name="peis"
                            :icons-path="`${$config.public.apiBase}/map-symbols`"
                            @loading-map="loadingMap = $event"
                        />
                        <!-- @mouse-click="clickPei" -->

                        <MapControlsGeosearch />
                        <MapControlsScale />
                        <MapControlsZoombox />
                        <MapControlsLocate />
                        <MapControlsMeasure />
                    </Map>
                </v-window-item>
            </v-window>
        </v-card>
        <NuxtPage
            :request-name="requestName"
            :default-url="defaultUrl"
            :translation-name="translationName"
            :permission-name="permissionName"
            @load-data="loadData()"
            @delete-data="deleteData($event)"
        />
    </div>
</template>

<script lang="ts">
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

export default {
    name: "ListeTournées",
    extends: tablePageInit,
    setup() {
        useHead({
            title: "Hydraclic | Tournée",
            meta: [
                {name: 'description', content: "Gestion des tournées"}
            ]
        })
        definePageMeta({
            middleware: ['auth']
        });
    },
    data() {
        return {
            peis: [],
            hasMap: true,
            permissionName: 'tour',
            requestName: 'tours',
            defaultUrl: '/gestion/tournees',
            translationName: 'gestion.tournees',
            valueAsViewLink: ['number'],
            columns: [
                {dataKey: 'commune.nom'},
                {dataKey: 'number'},
                {dataKey: 'type', transform: [this.toTranslate]},
                {dataKey: 'peis_count'},
                {dataKey: 'credentials.updated_at', transform: [this.isDate]},
            ],
            filtersOverload: ['commune_code', 'territory_id', 'number', 'type'],
            initSort: {
                key: "number",
                order: "asc"
            },
            tabs: 'list',

        }
    },
    mounted() {
        this.loadingMap = true
        this.$api.setRequest({
            url: `peis?format=geojson`,
            method: "GET",
        }).then((res) => {
            this.peis = res
            this.loadingMap = false
        })
    },
    methods: {
        updateColor(layer) {
            layer.setStyle({
                "color": "black",
                "weight": 1,
                "opacity": 1
            })
        }
    },
};
</script>
