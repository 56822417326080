import revive_payload_client_4sVQNw7RlN from "/builds/datakode/hydraclic-v2/nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/datakode/hydraclic-v2/nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/datakode/hydraclic-v2/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/datakode/hydraclic-v2/nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/datakode/hydraclic-v2/nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/datakode/hydraclic-v2/nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/datakode/hydraclic-v2/nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/datakode/hydraclic-v2/nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/datakode/hydraclic-v2/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/datakode/hydraclic-v2/nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/builds/datakode/hydraclic-v2/nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builds/datakode/hydraclic-v2/nuxt/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import notifier_TfOkCLHRTj from "/builds/datakode/hydraclic-v2/nuxt/packages/datakode/nuxt-datakode-utils/plugins/notifier.ts";
import api_client_t0dWRHSNDZ from "/builds/datakode/hydraclic-v2/nuxt/packages/datakode/nuxt-datakode-utils/plugins/api.client.ts";
import currentPath_yqNfcRq2j9 from "/builds/datakode/hydraclic-v2/nuxt/packages/datakode/nuxt-datakode-utils/plugins/currentPath.ts";
import currentUser_28J1aqNENu from "/builds/datakode/hydraclic-v2/nuxt/packages/datakode/nuxt-datakode-utils/plugins/currentUser.ts";
import translation_NgY8VMsLMG from "/builds/datakode/hydraclic-v2/nuxt/packages/datakode/nuxt-datakode-utils/plugins/translation.ts";
import getColor_Ghj8WPsFjF from "/builds/datakode/hydraclic-v2/nuxt/packages/datakode/nuxt-datakode-utils/plugins/getColor.ts";
import helper_Bdxgb2x3zG from "/builds/datakode/hydraclic-v2/nuxt/packages/datakode/nuxt-datakode-utils/plugins/helper.ts";
import formatDate_za3K2nEobv from "/builds/datakode/hydraclic-v2/nuxt/packages/datakode/nuxt-datakode-utils/plugins/formatDate.ts";
import leaflet_client_7dEOJA4hbk from "/builds/datakode/hydraclic-v2/nuxt/plugins/leaflet.client.ts";
import pixi_client_pmHejJdQ2P from "/builds/datakode/hydraclic-v2/nuxt/plugins/pixi.client.ts";
import vuetify_7h9QAQEssH from "/builds/datakode/hydraclic-v2/nuxt/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  notifier_TfOkCLHRTj,
  api_client_t0dWRHSNDZ,
  currentPath_yqNfcRq2j9,
  currentUser_28J1aqNENu,
  translation_NgY8VMsLMG,
  getColor_Ghj8WPsFjF,
  helper_Bdxgb2x3zG,
  formatDate_za3K2nEobv,
  leaflet_client_7dEOJA4hbk,
  pixi_client_pmHejJdQ2P,
  vuetify_7h9QAQEssH
]