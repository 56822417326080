import formatDate from '../utils/formatDate.js'
import formatDateTime from '../utils/formatDateTime.js'


export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.provide('formatDate', (date:string) => {
        return formatDate(date)
    })
    nuxtApp.provide('formatDateTime', (datetime:string) => {
        return formatDateTime(datetime)
    })
})
