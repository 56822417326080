<template>
    <div>
        <ModalsLoader :loading="$attrs.loading" />
        <div v-if="!$attrs.loading && data">
            <FormSection title="Configuration">
                <v-row no-gutters>
                    <v-col
                        cols="12"
                        sm="6"
                        class="pr-5"
                    >
                        <FormAutocomplete
                            :is-multiple="true"
                            :is-chips="true"
                            data-key="communes_complete"
                            edit-key="v"
                            :is-clearable="true"
                            :validation="v$?.data.communes"
                            validation-key="communes.*"
                            :validation-rules="validationRules"
                            :data="data"
                            :translation-name="translationName"
                            @update-data="console.log('event', $event); data.communes_complete = $event; data.communes = $event ? $event.map(e => e.id): []"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                        <FormAutocomplete
                            data-key="email_template"
                            edit-key="v"
                            :is-clearable="true"
                            :validation="v$?.data.email_template_id"
                            validation-key="email_template_id"
                            :validation-rules="validationRules"
                            :data="data"
                            :translation-name="translationName"
                            @update-data="data.email_template = $event; data.email_template_id = $event ? $event.id: null"
                        />
                        <!-- <div v-if="fullTemplate" class="text-caption">
                            <v-chip v-for="role in fullTemplate.roles_recipients" :key="role" size="x-small">
                                {{ role }}
                            </v-chip>
                            des communes seront notifiés
                        </div> -->
                    </v-col>
                </v-row>
            </FormSection>
            <FormSection title="Modification du modèle">
                <InfoCard
                    level="info"
                    text="À la sélection des communes et du modèle, la liste des destinaires est réinitialisée <br> Pour ajouter un e-mail, écrivez-le puis appuyez sur la touche Entrée"
                />
                <!-- <FormInput
                    data-key="recipients" :validation="v$?.data.recipients"
                    :validation-rules="validationRules" :data="data"
                    :translation-name="translationName"
                    @updateData="data.recipients = $event"
                /> -->
                <label class="v-label">{{ $translate(`${translationName}.recipients`) }}</label>
                <v-combobox
                    v-model="data.recipients"
                    theme="light"
                    variant="outlined"
                    :items="emails"
                    density="compact"
                    multiple
                    @update:search-input="$v.data['recipients.*'].$touch()"
                >
                    <template #selection="data">
                        <v-chip
                            :key="JSON.stringify(data.item)"
                            closable
                            v-bind="data.attrs"
                            :model-value="data.selected"
                            :disabled="data.disabled"
                            size="small"
                            @click:close="removeRecipient(data.item)"
                        >
                            <template #prepend>
                                <v-avatar
                                    color="primary"
                                    class="bg-accent text-uppercase"
                                    start
                                >
                                    {{ data.item.title.slice(0, 1) }}
                                </v-avatar>
                            </template>
                            {{ data.item.title }}
                        </v-chip>
                    </template>
                </v-combobox>

                <FormInput
                    data-key="subject"
                    :validation="v$?.data.subject"
                    :validation-rules="validationRules"
                    :data="data"
                    :translation-name="translationName"
                    @update-data="data.subject = $event"
                />

                <WysiwygEditor
                    ref="editor"
                    :content="data.content || ''"
                    :in-card="true"
                    :enabled-menu="['Gras', 'Italique', 'Ajouter un lien', 'Supprimer le lien', 'Aligner à gauche', 'Centrer', 'Aligner à droite', 'Titre 1', 'Paragraphe', 'Liste de points', 'Liste de nombres', 'Diviseur horizontal']"
                    placeholder="Écrivez votre contenu d'e-mail"
                />
            </FormSection>
        </div>
    </div>
</template>

<script lang="ts">
import formValidationInit from '~/packages/datakode/nuxt-datakode-utils/utils/validations/formValidationInit.vue'
export default {
    name : "EnvoiMail",
    extends : formValidationInit,
    setup() {
        useHead({
            title : "Hydraclic | Envoi de mail",
            meta : [
                { name : 'description', content : "Envoyer des mails aux communes" }
            ]
        })
        definePageMeta({
            middleware: ['auth']
        });
    },
    data () {
        return {
            emails : [],
            fullTemplate : null,
        }
    },
    computed : {
        communes () {
            return this.data?.communes
        },
        template () {
            return this.data?.email_template
        }
    },
    watch : {
        data () {
            this.data.communes_complete = []
        },
        template () {
            this.getTemplate ()
            this.getRecipients()
        },
        communes () {
            this.getRecipients()
        },
    },
    mounted () {
        if (this.data) {
            this.data.communes_complete = []
        }
    },
    methods : {
        getTemplate () {
            if (this.data.email_template_id) {
                this.$api.setRequest({
                    url : `email_templates/${this.data.email_template_id }`,
                    method : "GET"
                }).then( res => {
                    if (res) {
                        this.fullTemplate = res.data
                        this.data.content = res.data.content
                        this.data.subject = res.data.subject
                    }
                })
            }
        },
        getRecipients () {
            if (this.communes?.length && this.data.email_template_id) {
                this.$api.setRequest({
                    url : `sent_emails/create?commune_codes=${this.communes.join(',')}&email_template_id=${this.data.email_template_id}`,
                    // query :  [{name : "communes_codes", value :  this.communes.map(com => com.k)}, {name : "email_template_id", value :  this.template.k}],
                    method : "GET",
                }).then( res => {
                    this.emails = res.data.recipients
                    this.data.recipients = res.data.recipients
                })
            } else {
                this.emails = []
                this.data.recipients = []
            }
        },
        removeRecipient (data) {
            this.data.recipients = this.data.recipients.filter( rec => rec !== data.value)
        }
    },
}
</script>
