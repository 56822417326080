import { createVuetify, ThemeDefinition } from 'vuetify';

// import {aliases, mdi} from "vuetify/lib/iconsets/mdi";
import '@mdi/font/css/materialdesignicons.css'

// import fr from "vuetify/locale/fr";
import * as labsComponents from 'vuetify/labs/components'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const myCustomLightTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        'light' : "#f2f2f2",
        primary: '#fbba00',
        dark: '#363636',
        'light-primary' : '#fbede8',
        'primary-darken-1': '#3700B3',
        secondary: '#0090d1',
        'secondary-darken-1': '#018786',
        danger: '#d60e33',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    },
}

export default defineNuxtPlugin( (nuxt) => {
    const vuetify = createVuetify({
        /*display: {
            mobileBreakpoint: 'md'
        },*/
        ssr : true,
        // components,
        components: {
            ...components,
            ...labsComponents,
        },
        directives,

        theme: {
            defaultTheme: 'myCustomLightTheme',
            themes: {
                myCustomLightTheme,
            },
        },
        icons: {
            defaultSet: 'mdi',
        }
        
    })
    nuxt.vueApp.use(vuetify);
})