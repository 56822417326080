<template>
    <div id="utilisateurs">
        <ListActionsButtons 
            :query="$refs?.filters?.query"
            :request-name="requestName"
            :translation-name="translationName"
            :exportable="exportable"
            :default-url="defaultUrl"
            :permission-name="permissionName"
        />
        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name="permissionName"
            :filters="filters"
            :filters-overload="filtersOverload"
            :translation-name="translationName"
            :tabs-enabled="['basic']"
            @load-data="loadData()"
            @reset-pagination="$refs.pagination.resetPagination()"
        />
        <v-card class="pa-5">
            <ListTable
                ref="table"
                :value-as-view-link="valueAsViewLink"
                :permission-name="permissionName"
                :translation-name="translationName"
                :sortable="sortable"
                :default-url="defaultUrl"
                :loading="loading"
                :data="data"
                :columns="columns"
                :init-sort="initSort"
                @reset-pagination="$refs.pagination.resetPagination()"
                @load-data="loadData()"
                @delete-data="deleteData($event)"
                @restore-data="restoreData($event)"
            />
            <div>
                <ListPagination
                    ref="pagination"
                    :total="total"
                    :loading="loading"
                    :last-page="lastPage"
                    @load-data="loadData"
                />
            </div>
        </v-card>
        <NuxtPage
            :request-name="requestName"
            :default-url="defaultUrl"
            :translation-name="translationName"
            :permission-name="permissionName"
            @load-data="loadData()"
            @delete-data="deleteData($event)"
        />
    </div>
</template>

<script lang="ts">
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

export default {
    name : "StakeholderList",
    extends : tablePageInit,
    setup() {
        useHead({
            title : "Hydraclic | Acteurs",
            meta : [
                { name : 'description', content : "Gestion des acteurs" }
            ]
        })
        definePageMeta({
            middleware: ['auth']
        });
    },
    data () {
        return {
            permissionName : 'stakeholder',
            requestName : 'stakeholders',
            defaultUrl : '/administration/acteurs',
            translationName : 'administration.acteurs',
            valueAsViewLink : ['code'],
            columns : [
                { dataKey : 'code' },
                { dataKey : 'raison_sociale' },
                { dataKey : 'type', transform : [this.toTranslate], template : [this.isChips] },
                { dataKey : 'territory.t', template : [this.isChips] },
                { dataKey : 'documents', template : [this.isChips] },
            ],
            filtersOverload : ['code', 'raison_sociale', 'type', 'territory.id'],
            initSort : {
                // key : "name",
                // order : "asc"
            },
        }
    },
};
</script>
