<template>
    <v-container class="text-center h-screen">
        <v-row
            align="center"
            justify="center"
            class="h-100"
        >
            <v-col>
                <v-card
                    class="text-center"
                    color="transparent"
                    flat
                >
                    <div class="paragraph-text mt-2">
                        <div class="font-weight-bold">
                            Votre compte n'est pas configuré avec un territoire.
                        </div>
                        Veuillez contacter l'administration du site pour que votre compte soit modifié et pour accéder à toutes les fonctionnalités.
                    </div>
                    <v-btn
                        class="mt-7"
                        color="secondary"
                        @click="logout"
                    >
                        Se déconnecter
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<!--suppress JSUnresolvedReference -->
<script lang="ts">
import {useUserStore} from '~/store'
export default {
    name: "NoTerritory",
    setup() {
        // eslint-disable-next-line no-undef
        useHead({
            title: "Hydraclic | Erreur",
            meta: [
                {name: 'description', content: "Erreur"}
            ]
        })
        
        definePageMeta({ layout : 'empty' })

        const access_token = useCookie<string>('access_token')
        const userStore = useUserStore()
        return {
            access_token,
            userStore,
        }
    },
    mounted () {
        if (!this.userStore.currentUser.k) {
            this.$router.replace(`/`)
        }
    },
    methods: {
        async logout ()
        {
            await this.$api.setRequest({
                url : "logout",
                method : "POST",
            });
            this.access_token = null;
            await this.userStore.clearUser()
            this.$router.push('/')
        }
    }
}
</script>
