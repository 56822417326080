<template>
    <div>
        <div class="mr-3 my-6">
            <FormImportInit />
        </div>
        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name="permissionName"
            :filters="filters"
            :filters-overload="filtersOverload"
            :translation-name="translationName"
            @load-data="loadData()"
            @reset-pagination="$refs.pagination?.resetPagination()"
        />
        <v-card class="pa-5">
            <ListImportFieldsTable />
            <ListTable
                ref="table"
                :permission-name="permissionName"
                :translation-name="translationName"
                :sortable="sortable"
                :default-url="defaultUrl"
                :loading="loading"
                :data="data"
                :columns="columns"
                :value-as-view-link="valueAsViewLink"
                :init-sort="initSort"
                @reset-pagination="$refs.pagination?.resetPagination()"
                @load-data="loadData()"
                @delete-data="deleteData($event)"
            >
                <template #optionsList="{ item }">
                    <!-- PDF -->
                    <v-list-item
                        v-if="item && $permissionCheck(`${permissionName}_show`) && !item.deleted"
                        density="compact"
                        class="link-as-list-item"
                    >
                        <DocumentsDownloadLink
                            :filename="item.file_name"
                            :url="item.download_url"
                        >
                            <v-list-item-title class="text-caption">
                                <v-icon
                                    icon="mdi-download"
                                    size="small"
                                    class="mr-2"
                                    color="dark"
                                />
                                {{ $translate(`${translationName}.download`) }}
                            </v-list-item-title>
                        </DocumentsDownloadLink>
                    </v-list-item>
                </template>
            </ListTable>
            <div>
                <ListPagination
                    ref="pagination"
                    :total="total"
                    :loading="loading"
                    :last-page="lastPage"
                    @load-data="loadData"
                />
            </div>
        </v-card>
        <NuxtPage
            :request-name="requestName"
            :default-url="defaultUrl"
            :translation-name="translationName"
            :permission-name="permissionName"
            @load-data="loadData()"
        />
    </div>
</template>

<script lang="ts">
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

export default {
    name : "ImportPage",
    extends : tablePageInit,
    setup() {
        useHead({
            title : "Hydraclic | Import",
            meta : [
                { name : 'description', content : "Import de documents pour mettre à jour plusieurs PEI en même temps" }
            ]
        })
        definePageMeta({
            middleware: ['auth']
        });
    },
    data () {
        return {
            permissionName : 'importPei',
            requestName : 'import_peis',
            defaultUrl : '/gestion/import',
            translationName : 'gestion.import',
            filtersOverload : ['title', 'file_name', 'status', 'created_by', 'created_at'],
            valueAsViewLink : ['file_name'],
            columns : [
                { dataKey : 'credentials.created_at', transform : [this.isDateTime]},
                { dataKey : 'title'},
                { dataKey : 'file_name', template : [this.fileNameDownload]},
                { dataKey : 'status', transform : [this.toTranslate], template : [this.isChips]},
                { dataKey : 'credentials.created_by.name'},
            ],
            initSort : {
                // key : "commune",
                // order : "asc"
            },
        }
    },
    methods : {
        fileNameDownload (key, value, template/*, item*/) {
            let html = template
            html = `
                        <v-chip  size="small">
                        ${template}
                    </v-chip>

                `
            return html
            // <DocumentsDownloadLink
            //                             :filename="data.file_name"
            //                             :url="data.download_url"
            //                         >
            //                             <v-btn
            //                                 class="ml-5"
            //                                 color="info"
            //                                 icon="mdi-download"
            //                                 variant="text"
            //                             />
            //                         </DocumentsDownloadLink>
        }
    }

};
</script>
